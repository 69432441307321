import { TransactionsHandlerService } from './transactions-handler/transactions-handler.service';
import { EppPaymentSchedule } from '../models/billing/epp-payment-schedule.model';
import { CustomerService } from './customer.service';
import { forkJoin, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { PremiseService } from './premise.service';
// import { BrowserXhr, RequestOptions, ResponseContentType } from '@angular/http';
import { of } from 'rxjs';
import { Bill } from '../models/billing/bill.model';
import { LatestBill } from '../models/billing/latest-bill.model';
import { EppBillDetails } from '../models/billing/epp-bill-details.model';
import {
    BillingTransactionCollection,
    BillingTransactionCollectionAPI,
} from '../models/billing/billing-transaction-collection.model';
import { BillingOverview } from '../models/billing/billing-overview.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpOptions } from '../../core/services/http-options.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { CacheManager } from '../../core/services/cache-manager.service';
import { EppEligibility } from '../models/billing/epp-plan.model';
import * as CryptoJS from 'crypto-js';

export enum ParentPage {
    AccountOverview = 'account-overview',
    BillingOverview = 'billing-overview',
    BillPayments = 'bills-payments'
}

export enum BudgetPlan {
    EPP = 'EPP',
    LPP = 'LPP',
    NotBudgetPlan = 'Invalid'
}

@Injectable()
export class BillingService {
    private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].billingdetails;
    private apiGroupURL2 = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].billingdetails_v2;

    billingOverview: BillingOverview;
    BillingTransactionCollection: BillingTransactionCollection;
    latestBill: LatestBill;
    eppEligibility: EppEligibility;
    groupName = '';
    groupNo = '';
    accName = '';
    public salt: any = OSSAppConfig.OCP_API_SUB_KEY;
    constructor(
        private http: HttpClient,
        private httpOptions: HttpOptions,
        private premiseService: PremiseService,
        private customerService: CustomerService,
        private cacheManager: CacheManager,
        private transactionsHandler: TransactionsHandlerService
    ) { }

    /**
     * service that gets bill summary of
     * particular customer using debtor number
     * for non budget plan customer
     *
     * @param {number} debtornum
     * @returns {Observable<BillingOverview>}
     * @memberof BillingService
     */
    public getBillSummaryDetails(debtornum: number, from?): Observable<BillingOverview> {
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/billing.json`;
        if (from !== undefined) {

            const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/billing`;
            return this.http.get<BillingOverview>(url)
                .pipe(map((billingOverview: BillingOverview) => {
                    if (billingOverview.budgetPlanType) {
                        this.setBudgetPlanType(<BudgetPlan>billingOverview.budgetPlanType);
                    } else {
                        this.setBudgetPlanType(BudgetPlan.NotBudgetPlan);
                    }
                    return billingOverview;
                }), catchError((exception: Response | any) => throwError(exception))
                    , finalize(() => {
                    }));
        }
    }
    /**
     * Gets latest bill
     * @param debtornum
     * @returns latest bill
     */
    public getLatestBill(debtornum: number): Observable<LatestBill> {
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/latestBill.json`;
        const latestbillCache = this.cacheManager.quickRetrieve('latestBillResponse'+debtornum);
        if (latestbillCache) {
            return Observable.create(observer => {
                observer.next(latestbillCache);
                observer.complete();
            });
        }
        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL2}/customers/${debtornum}/latest-bill-v2`;
        return this.http.get<LatestBill>(url)
            .pipe(map((latestBill: any) => {
                this.cacheManager.quickSave('latestBillResponse'+debtornum, latestBill);
                return latestBill;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }


    /**
     * service that gets bill  history details of
     * particular customer using debtor number
     *
     * @param {string} debtornum
     * @returns {Observable<BillingTransactionCollection>}
     * @memberof BillingService
     */
    public getBillHistoryDetails(debtornum: string): Observable<BillingTransactionCollection> {
        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/transactionHistory.json`;
        return this.http.get<BillingTransactionCollectionAPI>(url)
            .pipe(map((billingTransactionCollection: BillingTransactionCollectionAPI) => {
                return this.transactionsHandler.parseTransactionHistory(billingTransactionCollection);
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }

    /**
     * service that gets bill summary of
     * particular customer using debtor number
     * for  budget plan customer
     *
     * @param {number} debtornum
     * @returns {Observable<EppBillDetails>}
     * @memberof BillingService
     */
    public getBudgetPlanBillSummaryDetails(debtornum: number): Observable<EppBillDetails> {
        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/epp-bill-details`;
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/epp-bill-details.json`;
        return this.http.get<EppBillDetails>(url)
            .pipe(map((eppBillDetails: any) => {
                return eppBillDetails;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Gets budget plan schedule
     * @param debtorNumber
     * @returns budget plan schedule
     */
    public getBudgetPlanSchedule(debtorNumber: string): Observable<EppPaymentSchedule> {
        //  const url = `${OSSAppConfig.jsonBasePath}/mocks/epp-bill-schedule.json`;

        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtorNumber}/epp-bill-details/schedule`;
        return this.http.get<EppPaymentSchedule>(url)
            .pipe(map((eppPaymentSchedule: EppPaymentSchedule) => {
                return eppPaymentSchedule;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Gets statement details
     * @param debtorNumber
     * @param statementId
     * @returns statement details
     */
    public getStatementDetails(debtorNumber: string, statementId: string): Observable<Bill> {
        const lastFetchedStatement = this.cacheManager.quickRetrieve('lastFetchedStatement');
        if (lastFetchedStatement
            && lastFetchedStatement.statementNumber === statementId) {
            return Observable.create(observer => {
                observer.next(lastFetchedStatement);
                observer.complete();
            });
        }
        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtorNumber}/statement/${statementId}`;
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/statement.json`;
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/statement-multipremise.json`;

        const bill$ = this.http.get<Bill>(url);
        const premisesDetails$ = this.premiseService.getPremises(debtorNumber);
        return forkJoin(bill$, premisesDetails$)
            .pipe(map(([bill, premisesDetails]) => {
                if (bill.premisesChargesAndReads) {
                    bill.premisesChargesAndReads.forEach(premise => {
                        /*
                        Premise MPRN and GPRN is required for UI.
                        These details are fetched from premises API and appended to statement details
                        If no data is available for the premise, premise is marked inactive
                        */
                        const premiseDetail = premisesDetails.find(prem => prem.premisesId === premise.premisesId);
                        if (premiseDetail) {
                            premise.isActive = true;
                            if (premiseDetail.mprn) {
                                premise.mprn = premiseDetail.mprn;
                            }
                            if (premiseDetail.gprn) {
                                premise.gprn = premiseDetail.gprn;
                            }
                        } else {
                            premise.isActive = false;
                        }
                        /*
                        If there are no charges and readings for electricity (or gas), API needs
                        to return empty array for electricityCharges and electricityInvoiceReadings (similarly for Gas).
                        This empty array is necessary, without which we can't identify the utilities included in the bill.
                        But due to a limitation in backend JSON parser, API can't return an empty array.
                        So, an array with NULL is returned instead.
                        If any of the required keys contain NULL array, it is converted to empty array.
                        */
                        if (premise.electricityCharges && premise.electricityCharges.every(charge => !charge)) {
                            premise.electricityCharges = [];
                        }
                        if (premise.electricityInvoiceReadings && premise.electricityInvoiceReadings.every(reading => !reading)) {
                            premise.electricityInvoiceReadings = [];
                        }
                        if (premise.gasCharges && premise.gasCharges.every(charge => !charge)) {
                            premise.gasCharges = [];
                        }
                        if (premise.gasInvoiceReadings && premise.gasInvoiceReadings.every(reading => !reading)) {
                            premise.gasInvoiceReadings = [];
                        }
                    });
                }
                this.cacheManager.quickSave('lastFetchedStatement', bill);
                return bill;
            })
                , catchError(exception => throwError(exception))
                , finalize(() => {

                }));
    }

    /**
     * service that gets the bill statement pdf
     * with debtor number, statement id and statement date
     *
     * @param {number} debtorNumber
     * @param {number} statementId
     * @param {number} statementDate
     * @returns {Observable<any>}
     * @memberof BillingService
     */
    public downloadBillAsPDF(debtorNumber: number, statementId: number, statementDate: number): Observable<any> {
        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/` +
            `${debtorNumber}/statement/${statementId}/bill-pdf/${statementDate}`;
        const options = {
            observe: 'response' as 'body',
            responseType: 'blob' as 'json'
        };
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get<any>(url, options)
            .pipe(map((data: any) => {
                let blob;
                if (data) {
                    blob = new Blob([data.body], { type: 'application/pdf' });
                }
                return {
                    filename: statementId + '.pdf',
                    data: blob
                };
            })
                , catchError((exception) => throwError(exception))
                , finalize(() => {

                }));
    }
    public isBillPdfAvailable(debtornum, statementId, statementDate) {
        const url = `${OSSAppConfig.DATA_API_BASE}/billing-details/customers/${debtornum}/statement/${statementId}/is-bill-pdf-available/${statementDate}`;
        return this.http.get<any>(url)
            .pipe(map((isBillPdfAvailable: any) => {
                return isBillPdfAvailable;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Sets bill details parent page
     * @param from
     */
    public setBillDetailsParentPage(from: ParentPage) {
        this.cacheManager.save('billDetailsParent', from);
    }
    /**
     * Gets bill details parent page
     * @returns bill details parent page
     */
    public getBillDetailsParentPage(): ParentPage {
        return <ParentPage>this.cacheManager.retrieve('billDetailsParent') || ParentPage.BillingOverview;
    }
    /**
     * Sets account history parent page
     * @param from
     */
    public setAccountHistoryParentPage(from: ParentPage) {
        this.cacheManager.save('accountHistoryParent', from);
    }
    /**
     * Gets account history parent page
     * @returns account history parent page
     */
    public getAccountHistoryParentPage(): ParentPage {
        return <ParentPage>this.cacheManager.retrieve('accountHistoryParent') || ParentPage.BillingOverview;
    }
    /**
     * Sets budget plan type
     * @param plan
     */
    public setBudgetPlanType(plan: BudgetPlan) {
        this.cacheManager.quickSave('budgetPlanType', plan);
    }
    /**
     * Gets budget plan type
     * @returns budget plan type
     */
    public getBudgetPlanType(): Observable<BudgetPlan> {
        const budgetPlanType = this.cacheManager.quickRetrieve('budgetPlanType');
        if (budgetPlanType) {
            return Observable.create(observer => {
                observer.next(budgetPlanType);
                observer.complete();
            });
        } else {
            const accountNumber = Number(this.customerService.getAccountNo());
            if (accountNumber) {
                return this.getBillSummaryDetails(accountNumber, 'from-faq').pipe(map(billSummary => {
                    if (billSummary.budgetPlanType) {
                        this.setBudgetPlanType(<BudgetPlan>billSummary.budgetPlanType);
                        return <BudgetPlan>billSummary.budgetPlanType;
                    } else {
                        this.setBudgetPlanType(BudgetPlan.NotBudgetPlan);
                        return BudgetPlan.NotBudgetPlan;
                    }
                }), catchError(error => throwError(error)));
            } else {
                this.setBudgetPlanType(BudgetPlan.NotBudgetPlan);
                return of(BudgetPlan.NotBudgetPlan);
            }
        }
    }
    /**
     * Service that gets epp eligibility
     * @param debtornum
     * @returns {Observable<EppEligibility>}
     * @memberof BillingService
     */
    public getEPPEligibility(debtornum: any): Observable<EppEligibility> {
        const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/epp-eligibility`;
        return this.http.get<EppEligibility>(url)
            .pipe(map((eppEligibility: any) => {
                return eppEligibility;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {
                }));
    }
    /**
     * Service to submit EPP signup
     * @param debtornum
     * @param data
     *  @memberof BillingService
     */
    public confirmSignUpBP(debtornum, data) {
        this.cacheManager.quickRemove('accountDetails' + debtornum);
        return this.http.post(`${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/epp-opt-in`, data)
            .pipe(map((response: Response) => response
            ), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Service to fetch tooltip text for bill details items from SSE CMS
     * @memberof BillingService
     */
    public getBillHoverText() {
        const toolTipsCache = this.cacheManager.retrieve('tooltipTexts');
        if (toolTipsCache) {
            return Observable.create(observer => {
                observer.next(toolTipsCache);
                observer.complete();
            });
        }
        const url = OSSAppConfig.billHoverTextUrl;
        return this.http.get<any>(url)
            .pipe(map((billHoverText: any) => {
                this.cacheManager.save('tooltipTexts', billHoverText.items);
                return billHoverText.items;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }

    /**
     * service that gets accounts list
     * particular customer using debtor number
     *
     * @param {string} debtornum
     * @returns {Observable<array>}
     * @memberof BillingService
     */
    public downloadBulkBills(params): Observable<any> {
        /*  params = {
              "debtorNums": [
              122970
              ],
              "startDate": "01-01-2020",
              "endDate": "01-10-2021"
              }
         */
        const url = `${OSSAppConfig.DATA_API_BASE}/billing-details/v1/customers/bulk-bill-pdf-download`;
        // const url = `${OSSAppConfig.jsonBasePath}/mocks/account-list.json`;
        const options = {
            observe: 'response' as 'body',
            responseType: 'blob' as 'json'
        };
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        return this.http.post<any>(url, params, options)
            .pipe(map((data) => {
                let blob; let name;
                if (data) {
                    let nameHeader = data.headers.get('Content-Disposition');
                    name = nameHeader?.split('=')[1];

                    blob = new Blob([data.body], { type: 'application/zip' });
                }
                return {
                    filename: name,
                    data: blob
                };

            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Sets group
     * @param name
     * @param identity
     */
    public setGroup(name, identity) {
        this.groupName = name;
        this.groupNo = identity;
    }
    /**
     * Gets group
     * @returns
     */
    public getGroup() {
        return {
            grpName: this.groupName,
            grpNo: this.groupNo
        };
    }
    /**
     * Sets account
     * @param name
     */
    public setAccount(name) {
        this.accName = name;
    }
    /**
     * Gets account
     * @returns
     */
    public getAccount() {
        return this.accName;
    }
    /**
     * Encrypts txt
     * @param data
     * @returns txt
     */
    encryptTxt(data: any): string {
        // Encrypt
        if (data) {
            data = data.toString();
        }
        const encryptedData: string = CryptoJS.AES.encrypt(data, this.salt).toString();
        return encryptedData;
    }
    /**
     * Decrypts txt
     * @param data
     * @returns
     */
    decryptTxt(data: any) {
        let bytes = CryptoJS.AES.decrypt(data, this.salt);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    getBulkPDFAPIAvailability() {
        const url = `${OSSAppConfig.DATA_API_BASE}/billing-details/v1/customers/bulk-pdf-api-availability`;

        return this.http.get(url)
            .pipe(map((info: any) => {
                return info;
            }), catchError((exception: Response | any) => Observable.throw(exception))
                , finalize(() => {
                }));
    }

    getFinalisedSingleAccountData(debtorNumber) {
        const url = `${OSSAppConfig.DATA_API_BASE}/account-details/v1/${debtorNumber}/finalizedAccount`;

        return this.http.get(url)
            .pipe(map((info: any) => {
                // console.log(info);
                sessionStorage.setItem('isFinalizedAccount', info?.finalizedAccount?.toString());

                return info;
            }), catchError((exception: Response | any) => exception)
                , finalize(() => {
                }));
    }

}
