import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { OSSAppConfig } from '../../core/config/app-config';

const cKey = CryptoJS.enc.Utf8.parse(OSSAppConfig.OCP_API_SUB_KEY);
const cIv = CryptoJS.enc.Utf8.parse(OSSAppConfig.OCP_API_SUB_KEY);

@Injectable()
export class UrlEncryptorService {


  private key = CryptoJS.enc.Utf8.parse(OSSAppConfig.OCP_API_SUB_KEY);
  private iv = CryptoJS.enc.Utf8.parse(OSSAppConfig.OCP_API_SUB_KEY);

  constructor() { }

  /**
     * @memberof urlEncryptor
     * @param data
     * Function to AES encrypt the given data object
     */
  public aesEncrypt(text: any): string {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    let replaceURLSpecial = encrypted.toString().replaceAll('/','xslair').replaceAll('+','xplair').replaceAll('=','xeqair')

    return replaceURLSpecial

  }

  public static aesEncryptLegacy(text: any): string {

    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), cKey, {
      keySize: 128 / 8,
      iv: cIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    let replaceURLSpecial = encrypted.toString().replaceAll('/','xslair').replaceAll('+','xplair').replaceAll('=','xeqair')

    return replaceURLSpecial

  }


  /**
   * @memberof urlEncryptor
   * @param data
   * Function to decrypt the given data object
   */
  public aesDecrypt(decString) {
    let decStringdec = decString.replaceAll('xslair', '/').replaceAll('xplair', '+').replaceAll('xeqair', '=');
    var decrypted = CryptoJS.AES.decrypt(decStringdec, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


}
